@import 'variables.scss';
.modal-body {
    overflow-y: auto;
    padding: $ng4h-modal-inner-padding !important;
    max-height: calc(90vh - 63px - 71px); // 90% vh minus the header and  footer hight
}

.mat-dialog-container {
    padding: 0 !important;
    max-height: 100% !important;
    height: inherit !important;
}
.transparent .mat-dialog-container {
    box-shadow: none;
    background: rgba(0, 0, 0, 0.0);
    overflow: hidden;
}
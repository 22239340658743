.ng4h-margin {
    margin: $ng4h-margin;
}

.ng4h-margin-right {
    margin-right: $ng4h-margin;
}

.btn {
&.ng4h-margin-right {
        margin-right: $ng4h-margin-small;
    }
}

.ng4h-margin-left {
    margin-left: $ng4h-margin;
}

.ng4h-margin-top {
    margin-top: $ng4h-margin;
}

.btn {
    &.ng4h-margin-top {
        margin-top: $ng4h-margin-small;
    }
}

.ng4h-margin-bottom {
    margin-bottom: $ng4h-margin;
}

.ng4h-margin-horizontal {
    margin-top: $ng4h-margin;
    margin-bottom: $ng4h-margin;
}

.ng4h-margin-vertical {
    margin-right: $ng4h-margin;
    margin-left: $ng4h-margin;
}

.ng4h-margin-small {
    margin: $ng4h-margin-small;
}
.ng4h-margin-right-small {
    margin-right: $ng4h-margin-small;
}
.ng4h-margin-left-small {
    margin-left: $ng4h-margin-small;
}
.ng4h-margin-top-small {
    margin-top: $ng4h-margin-small;
}
.ng4h-margin-bottom-small {
    margin-bottom: $ng4h-margin-small;
    //margin-left: 8px;
}
.ng4h-margin-horizontal-small {
    margin-top: $ng4h-margin-small;
    margin-bottom: $ng4h-margin-small;
}
.ng4h-margin-none {
  margin: 0px;
}
.ng4h-margin-vertical-small {
    margin-right: $ng4h-margin-small;
    margin-left: $ng4h-margin-small;
}

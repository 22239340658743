.ng4h-padding {
    padding: $ng4h-padding;
}
.ng4h-padding-right {
    padding-right: $ng4h-padding;
}

.ng4h-padding-left {
    padding-left: $ng4h-padding;
}

.ng4h-padding-top {
    padding-top: $ng4h-padding;
}

.ng4h-padding-bottom {
    padding-bottom: $ng4h-padding;
}

.ng4h-padding-horizontal {
    padding-top: $ng4h-padding;
    padding-bottom: $ng4h-padding;
}

.ng4h-padding-vertical {
    padding-right: $ng4h-padding;
    padding-left: $ng4h-padding;
}

.ng4h-padding-small {
    padding: $ng4h-padding-small;
}
.ng4h-padding-right-small {
    padding-right: $ng4h-padding-small;
}

.ng4h-padding-left-small {
    padding-left: $ng4h-padding-small;
}

.ng4h-padding-top-small {
    padding-top: $ng4h-padding-small;
}

.ng4h-padding-bottom-small {
    padding-bottom: $ng4h-padding-small;
}

.ng4h-padding-horizontal-small {
    padding-top: $ng4h-padding-small;
    padding-bottom: $ng4h-padding-small;
}

.ng4h-padding-vertical-small {
    padding-right: $ng4h-padding-small;
    padding-left: $ng4h-padding-small;
}

div.member-footer {
    text-align: center;
    padding-top: 20px;
    margin-bottom: -20px;
}
@import "variables.scss";
.secondary-header{
  /*background-color: $ng4h-secondary-light;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.06), 0 5px 6px -5px rgba(243, 250, 251, 0.1);*/
  background: linear-gradient(60deg, #f4f5f8, #f4f5f8);
  border-bottom: solid 1px #dddddd;
  line-height: 1;
}
.secondary-header-title {
  font-weight: bold;
}

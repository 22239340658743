@import "variables.scss";
// .h1,
// .h2,
// .h2,
// .h3,
// .h4,
// .h5,
// .h6,
// h1,
// h2,
// h2,
// h3,
// h4,
// h5,
// h6,
// b,
// strong,
// th {
//     color: $ng4h-bold-text;
// }

small.ng4h-small-header {
    letter-spacing: 1px;
}

.ng4h-danger {
    color: $ng4h-danger !important
}

h3.ng4h-page-header {
    margin-bottom: $ng4h-margin
}

.ng4h-brand {
    color: $ng4h-company;
}

.ng4h-center-text {
    text-align: center;
}

.help-text {
    color: $ng4h-green;
}
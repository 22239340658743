@include mat-core();
@import 'variables.scss';
// color matrix for the material colors
// this is how you specify the colors for the material components
// http://mcg.mbitson.com/#!?mcgpalette0=%232c9ab7
$ng4h-mat-material: (
        50: #eaf3fb,
        100: #cbe0f5,
        200: #a8ccef,
        300: #85b8e8,
        400: #6aa8e3,
        500: #5099de,
        600: #4991da,
        700: #4086d5,
        800: #377cd1,
        900: #276bc8,
        A100: #ffffff,
        A200: #d3e4ff,
        A400: #a0c5ff,
        A700: #86b6ff,
        contrast: (50: #000000, 100: #000000, 200: #000000, 300: #000000, 400: #000000, 500: #fff, 600: #fff, 700: #ffffff, 800: #ffffff, 900: #ffffff, A100: #000000, A200: #000000, A400: #000000, A700: #000000,)
);
$ng4h-mat-primary: mat-palette($ng4h-mat-material);
$ng4h-mat-accent: mat-palette($ng4h-mat-material, 400, 300, 600);
$ng4h-mat-theme: mat-light-theme($ng4h-mat-primary, $ng4h-mat-accent,);
@include angular-material-theme($ng4h-mat-theme);
// Typography
$ng4h-typography: mat-typography-config($font-family: $ng4h-font-family, $caption: mat-typography-level(14px, 18px, 500),);
@include angular-material-typography($ng4h-typography);

mat-datepicker-toggle {
  position: absolute;
  right: 15px;
  top: -1px;
  z-index: 3;
}

mat-sidenav {
  width: 200px !important;
}

mat-step-header {
  overflow: visible !important;
}

mat-mdc-tab-group {
  font-family: inherit !important;
}

mat-mdc-table {
  font-family: $ng4h-font-family !important;
}

ng4h-card-content {
  ng4h-table {
    margin-top: -20px;
    margin-right: -20px;
    margin-bottom: -20px;
    margin-left: -20px;
  }

  .mat-mdc-table {
    border-spacing: 0 !important;
    /*border-top: solid 1px #ddd !important;
    margin-top: 20px !important;*/
  }

  th.mat-mdc-header-cell {
    background-color: $ng4h-secondary !important;
  }

}

ng4h-manager-root .mat-mdc-tab-list {
  margin-left: 30px;
}

ng4h-manager-root .mat-mdc-tab-link {
  /*color: $ng4h-green;
  font-weight: 600 !important;*/
}

ng4h-manager-root .mat-mdc-tab-link.mat-mdc-tab-label-active {
  opacity: 1 !important;
}

ng4h-system-manager-root .mat-mdc-tab-list {
  margin-left: 30px;
}

ng4h-system-manager-root .mat-mdc-tab-link {
  /*color: $ng4h-green;
  font-weight: 600 !important;*/
}

ng4h-system-manager-root .mat-mdc-tab-link.mat-mdc-tab-label-active {
  opacity: 1 !important;
}

td.mat-mdc-cell {
  border-bottom: 0 !important;
}

tr.mat-mdc-row {
  border-bottom: 1px solid #ddd;

  &:last-child {
    border-bottom: 0 !important;

    td {
      &:first-child {
        border-bottom-left-radius: 3px;
      }

      &:last-child {
        border-bottom-right-radius: 3px;
      }
    }
  }
}

tr.mat-header-row {
  border-bottom: 1px solid #ddd;
  /*border-top: 1px solid #ddd;*/
  height: 36px !important;
}

// .cdk-overlay-container {
//   z-index: 1 !important;
// }
td.mat-mdc-cell {
  font-size: 15px;
}

.mat-checkbox-layout {
  margin-bottom: 0 !important;
}

.mat-drawer-backdrop {
  position: fixed !important;
}

.mat-drawer-container {
  background-color: $ng4h-secondary;
  height: 100%;
}

/* These go together
-------------------->*/
.mat-mdc-header-cell {
  font-size: 0.9rem !important;
  white-space: pre-line;

  &:first-child {
    border-top-left-radius: 3px;
  }

  &:last-child {
    border-top-right-radius: 3px;
  }
}

.square-header {
  thead {
    tr {
      th.mat-mdc-header-cell {
        &:first-child {
          border-top-left-radius: 0 !important;
        }

        &:last-child {
          border-top-right-radius: 0 !important;
        }
      }
    }
  }
}

/*<------------------->*/
.mat-horizontal-content-container {
  padding: 0 !important;
}

.mat-horizontal-stepper-header-container {
  max-width: 100%;
  overflow-x: auto;
}

.mat-menu-content {
  padding: 8px;
  margin: 8px;
}

.mat-select-panel {
  margin-top: 40px;
}

.mat-mdc-tab-header {
  border-bottom: 1px solid $ng4h-border !important;
}

.mat-mdc-tab-label {
  font-family: $ng4h-font-family !important;
  height: 65px !important;
}

.mat-mdc-tab-label-container {
  padding-left: 15px !important;
}

.mat-mdc-tab-link {
  font-family: $ng4h-font-family !important;
  font-size: 100% !important;
}

.mat-mdc-table {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  font-family: $ng4h-font-family !important;

  .incomplete-enrollment {
    color: #AAAAAA;
  }

  .awaiting-approval {
    color: #6f14fd;
  }

  .enrollment-approved {
    color: #0C6E6E;
  }

  .outstanding-invoice {
    color: #E83E8C;
  }

  .screening-not-approved {
    color: #E83E8C;
  }

  .trainings-not-complete {
    color: #E83E8C;
  }

  .approval-state {
    width: 100%;
    text-align: center;
  }

  .member-search-email-button {
    margin-top: 10px;
  }
}

mat-card.mat-card {
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.08) !important;
  border: 1px solid $ng4h-border !important;
  border-radius: .25rem !important;
}

.mat-badge-center > .mat-badge-content {
  top: 0 !important;
}

.ng4h-nav-link.mat-badge-center > .mat-badge-content {
  top: 4px !important;
  right: 4px !important;
}

mat-list-item > .mat-badge-content {
  top: 30px !important;
  left: -10px !important;
}

.mat-mdc-list-item:hover:not(.selected) {
  background: rgba(0, 0, 0, .04);
  cursor: pointer;
}

.mat-mdc-list-item.selected {
  background: rgba(0, 0, 0, .06);
}

.historical-list-item {
  .mat-mdc-list-item-content {
    .mat-list-text {
      padding-left: 10px !important;
    }
  }
}

.mat-mdc-list-item,
.mat-mdc-list-item-line,
.mat-mdc-list-option {
  font-family: Roboto, Helvetica Neue, sans-serif !important
}

.mdc-form-field,
.mdc-label {
  font-size: 16px;
}

.mat-mdc-menu-panel {
  .mat-mdc-menu-content {
    margin: 8px;
    padding: 8px;
    list-style-type: none;
  }
}

.invoice-header {
  background-color: $ng4h-secondary !important;
  border-bottom: 1px solid $ng4h-border !important;
}

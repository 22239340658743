@import 'variables.scss';

form label.form-control-label {
  max-height: 30px;
}

div.form-group + div.form-group {
  .form-control-label {
    &.col-3, &.col-4, &.col-5, &.col-6, &.col-7, &.col-8,
    &.col-md-3, &.col-md-4, &.col-md-5, &.col-md-6, &.col-md-7, &.col-md-8,
    &.col-lg-3, &.col-lg-4, &.col-lg-5, &.col-lg-6, &.col-lg-7, &.col-lg-8 {
      border-top: 1px solid #f5f5f5;
      //border-bottom: 1px solid #f5f5f5;
      padding-top: 8px;
    }
  }
}

.alert {
  &.alert-manager-control-danger {
    background-color: #FFDFEA;
  }

  &.alert-manager-control-warning {
    background-color: #fff3cd;
  }
}

.coupon-input-row {
  border: solid 1px #ddd;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;
}

.ng4h-required:after {
  content: "*";
  color: #fb1f34; //#ff0019;
  vertical-align: top;
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  margin-left: 5px;
}

.modal-title:after {
}

form {
  width: 100%;
}

.search-term {
  height: 44px !important;
}

.search-date {
  border-top-right-radius: .25rem !important;
  border-bottom-right-radius: .25rem !important;
  min-height: 44px !important;
}

.form-row {
  margin-bottom: -5px !important;
}

.col-form-label {
  border-bottom: 1px solid #efefef;
}

.enrollment-fee-role-header {
  background: #f9f9f9;
  padding: 8px;
  border-bottom: 1px solid #e9e9e9;
}

.general-header {
  background: #f9f9f9;
  padding: 8px;
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 15px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

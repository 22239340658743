$ng4h-font-family: "Source Sans Pro", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
// RegistrationMax company color
$ng4h-company: #E0457B;
$ng4h-green: #0C6E6E; /*#009959*/
$ng4h-green-dark: #005961;
$ng4h-green-light: #f3fafb;
$ng4h-orange: #ff9800;
$ng4h-orange-dark: #dc8300;
$ng4h-pink-light: #EC407A;
$ng4h-pink: #e91e63;
$ng4h-pink-dark: #D81B60;
// Primary Application color
// Used for primary buttons, checked check-boxes profile background etc
$ng4h-primary: #337ab7; //#2a7ed8;
// Button border
$ng4h-primary-dark: #286090; //#4086d5;
// Input Focus
$ng4h-primary-light: #85b8e8;
// Secondary Application Color
// Secondary buttons, sidebar background etc
$ng4h-secondary: #f8f8f8;
// For when you need to add additional contract to $ng4h-secondary
$ng4h-secondary-dark: #ced4da; //#dcdcdc;
$ng4h-secondary-extra-dark: #373434;
$ng4h-secondary-extra-dark-light: #5e5959;
$ng4h-form-control-border: #919197;
// Main application background color, color for icons and text inside primary color
$ng4h-secondary-light: white;
// Highlight color for selected item
$ng4h-selected-highlight: #F0F9FF;
// Primary text color
$ng4h-primary-text-color: rgba(0, 0, 0, 0.87);
// Text color for bold
$ng4h-bold-text: #555459;
// Default icon color
$ng4h-icons: #aaaaaa;
// 1px border color
$ng4h-border: #ddd;
// delete buttons, 'exit event' text
$ng4h-danger: #ff0019;
$ng4h-danger-light: #f55a4e;
// Height of the application header
$ng4h-header-height: 80px;
// Padding around the main content (between nav bars)
$ng4h-main-content-padding-xl: 60px;
$ng4h-main-content-padding-lg: 40px;
$ng4h-main-content-padding-md: 25px;
$ng4h-main-content-padding-sm: 15px;
// Maximum width of main content so it doesn't stretch on 1440p+ screens
$ng4h-main-content-max-width: 1360px;

// Margin between elements in main content (normally margin-bottom)
$ng4h-margin: 30px;
// Padding for the component content in the main content
$ng4h-padding: 8px;
$ng4h-padding-small: 8px;
// Margin-right of buttons (space between th em)
$ng4h-margin-small: 15px;
// Slight box shadow under application header
$ng4h-header-box-shadow: 0 0 4px rgba(0, 0, 0, .12);
// inner box shadow of inputs
$ng4h-form-control-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
//padding for modal-body
$ng4h-modal-inner-padding: 30px;
// sidebar nav link padding
$ng4h-nav-link-padding: 5px;
// Highlight color for selected item
$ng4h-row-hover: #eaedf7; // #dfe5f7; // #ffff99;

$form-invalid: #dc3545;

/*** Angular 2 link without href ***/

$link-color: $ng4h-primary;
a:not([href], .link) {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

a {
    font-weight: 500;
    &[href]:hover {
        text-decoration: underline;
    }
}
a:not([href], .link), a:not([href], .link):hover {
    color: inherit;
    text-decoration: none;
}
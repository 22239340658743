@import "variables.scss";

.table-wrap {
  margin-top: -20px;
  margin-right: -20px;
  margin-bottom: -20px;
  margin-left: -20px;
  display: block;
}


.cdk-row:hover {
  background-color: $ng4h-row-hover !important;
  cursor: pointer;

  .ng4h-bg-secondary {
    background-color: $ng4h-row-hover;
  }
}

.ng4h-table {
  .row {
    border-top: 2px solid $ng4h-border;
    // border-right: 1px solid $ng4h-border;
    // border-left: 1px solid $ng4h-border;
  }

  .row:last-child {
    // border-bottom: 1px solid $ng4h-border;
    border-bottom: solid 2px $ng4h-border;
  }

  h4 {
    // border-top: solid 2px $ng4h-border;
    padding-top: .5rem;


    margin-right: -15px;
    margin-left: -15px;
  }

  .row.enable-hover:hover {
    background-color: $ng4h-row-hover;

    .ng4h-bg-secondary {
      background-color: $ng4h-row-hover;
    }
  }

  .table-row {
    border-top: 2px solid $ng4h-border;

    &:hover {
      background: whitesmoke;
    }

    // border-right: 1px solid $ng4h-border;
    // border-left: 1px solid $ng4h-border;
  }

  .table-row:last-child {
    // border-bottom: 1px solid $ng4h-border;
    border-bottom: solid 2px $ng4h-border;
  }


  .col-1,
  .col-3,
  .col-4,
  .col-8,
  .col-2,
  .col-6,
  .col-5,
  .col-7 {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .col-4 {
    text-align: right;
  }
}

.ng4h-custom-field-table {
  .row {
    border-top: 2px solid $ng4h-border;
    // border-right: 1px solid $ng4h-border;
    // border-left: 1px solid $ng4h-border;
  }

  h4 {
    // border-top: solid 2px $ng4h-border;
    padding-top: .5rem;
    margin-right: -15px;
    margin-left: -15px;
  }

  .row.enable-hover:hover {
    background-color: $ng4h-row-hover;

    .ng4h-bg-secondary {
      background-color: $ng4h-row-hover;
    }
  }

  .table-row {
    border-top: 2px solid $ng4h-border;

    &:hover {
      background: whitesmoke;
    }

    // border-right: 1px solid $ng4h-border;
    // border-left: 1px solid $ng4h-border;
  }

  .table-row:last-child {
    // border-bottom: 1px solid $ng4h-border;
    border-bottom: solid 2px $ng4h-border;
  }

  .col-1,
  .col-3,
  .col-4,
  .col-8,
  .col-2,
  .col-6,
  .col-5,
  .col-7 {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .col-4 {
    text-align: right;
  }
}

table {
  width: 100%;
}

.required-star {
  margin-left: 4px;
  margin-right: -4px;
  font-size: 60%;

  i {
    color: crimson;
  }
}

.required-star-header {
  margin-right: -4px;
  font-size: 80%;
  float: right;

  i {
    color: crimson;
  }
}

th {
  position: sticky;
  top: 0;
}

// .mat-header-cell {
//     color: $ng4h-bold-text !important;
// }

// .mat-mdc-row.ng4h-selected {
//     background-color: $ng4h-secondary-dark;
// }

// .mat-mdc-row:hover:not(.ng4h-selected) {
//     cursor: pointer;
//     background-color: $ng4h-secondary;
// }
// td{
//     border-bottom: 1px solid $ng4h-border !important;
//     border-right: 1px solid $ng4h-border !important;
//     padding-left: 24px !important;
// }
// td:first-of-type{
//     border-left: 1px solid $ng4h-border !important;
// }
// th{
//     border-bottom: 1px solid $ng4h-border !important;
//     border-top: 1px solid $ng4h-border !important;
//     border-right: 1px solid $ng4h-border !important;
//     padding-left: 24px !important;
// }
// th:first-of-type{
//     border-left: 1px solid $ng4h-border !important;
// }
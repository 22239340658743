/* You can add global styles to this file, and also import other style files */

@import 'variables.scss';
@import '/node_modules/@angular/material/theming';

@import 'header.scss';
@import 'panel.scss';
@import 'table.scss';
@import 'list.scss';
@import 'border.scss';
@import 'text.scss';
@import 'icons.scss';
@import 'creditcard.scss';
@import 'modal.scss';
@import 'margin.scss';
@import 'padding.scss';
@import 'anchor.scss';
@import 'forms.scss';
@import 'background.scss';
@import 'navigation.scss';
@import 'boostrap-overrides.scss';
@import 'material-overrides.scss';

@import 'node_modules/ngx-toastr/toastr';

@import '~@fortawesome/fontawesome-pro/css/all.min.css';

@include mat-core();

html {
    height: 100%;
    overflow: hidden;
}

body {
    font-size: 100% !important;
    font-family: $ng4h-font-family !important;
    overflow-y: auto;
    height: 100%;
    background-color: $ng4h-secondary;
    line-height: 1.3 !important;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

@media(max-width:768px) {
    .main {
        padding: $ng4h-main-content-padding-sm;
    }
}

@media(min-width:768px) {
    .main {
        padding: $ng4h-main-content-padding-md;
    }
}

@media(min-width:922px) {
    .main {

        padding: $ng4h-main-content-padding-lg;
    }
}

@media(min-width:1200px) {
    .main {

        padding: $ng4h-main-content-padding-xl;
    }
}

.main {

    // max-width: 1360px;
    // min-height: calc(100vh - $ng4h-header-height);
}

.hierarchy-tree-overlay {
    background-color: $ng4h-secondary-light;
    border-radius: 4px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    overflow-y: auto;
}

.read-only {
    pointer-events: none;
}

.information-well {
    border-color: #ddd;
    background-color: $ng4h-secondary;
    p{
        margin-bottom: 8px;
    }
}
@import 'variables.scss';
.mat-tab-link {
  padding: 0 30px !important;

  max-width: 50%;
  min-width: 0 !important;
  font-size: 15px;
}

.mat-ink-bar {
  /*background-color: $ng4h-green !important;*/
  height: 4px !important;
}


.mat-tab-label-active {
  font-weight: 700;
}


.ng4h-nav-link.ng4h-nav-link-header {
    padding-left: 1.5rem;
    padding-bottom: 15px;
}

.ng4h-nav-link.active {
    // color: $ng4h-bold-text !important;
    font-weight: 700;
}

.active>i {
    color: $ng4h-primary;
}

i {
    width: 18px;
}
ng4h-sidebar-accordion-contents .ng4h-nav-link {
    padding-left: 60px;
}
.ng4h-nav-header{
    display: block;
    padding-top: $ng4h-nav-link-padding;
    padding-bottom: $ng4h-nav-link-padding;
    padding-left: 40px;
    padding-bottom: 4px;
    padding-top: 4px;
    cursor: pointer;
    color: $ng4h-primary-text-color;
    &.active{
        font-weight: bolder;
    }
    &.active:hover {
        text-decoration: none;
        color: $ng4h-primary-text-color;
        font-weight: bolder;
      }
      &:hover {
        background-color: #fff !important;
        border-top-right-radius: 90px;
        border-bottom-right-radius: 90px;
        color: $ng4h-primary-text-color;
        text-decoration: none !important;
        font-weight: bolder;
      }
}
.ng4h-nav-link {
  display: block;
  padding-left: 40px;
  padding-bottom: 3px;
  padding-top: 3px;
  border-radius: 0;
  cursor: pointer;
  color: $ng4h-primary-text-color;
  font-size: 110%;
  font-weight: 400;
  margin-right: 10px;
  &.active {
    /*box-shadow: 5px 0px $ng4h-primary-dark inset;
    background-color: #dedee0;*/
    background-color: #fff;
    border-top-right-radius: 90px;
    border-bottom-right-radius: 90px;
    box-shadow: inset 0 0 0 1px #aaa;
  }
  &.active:hover {
    text-decoration: none;
    color: $ng4h-primary-text-color;
  }
  &:hover {
    background-color: #fff !important;
    border-top-right-radius: 90px;
    border-bottom-right-radius: 90px;
    color: $ng4h-primary-text-color;
    font-weight: 700;
    text-decoration: none !important;
  }
}

.user-info {
    padding-top: 5px;
    padding-left: 25px;
}

.user-info-name {
    font-weight: 500;
    padding-top: 10px;
}

.ng4h-nav-link i {
    width: 18px;
}

.nav {
    margin-bottom: 20px;
}

.nav-item{
  
}

.nav-item>.ng4h-nav-link>i.fa {
    color: $ng4h-primary;
}

.clickable {
    cursor: pointer;
}


.fa-chevron-up {
    right: 0;
    margin-right: 1rem;
    position: absolute;
}

.drawer-sidenav {
    width: 280px;
    border-right: 1px solid $ng4h-border;
}

.header-title {
    font-size: 18pt;
}

.subtext {
    padding-left: 3.5rem;
    font-size: small;
}

.manager-expand {
    float: right;
    cursor: pointer;
}

.manager-expand>i {
    margin: 11px;
    font-size: 15pt;
}

hr.sidebar-divider {
    margin-bottom: .5rem;
    margin-top: .5rem;
    margin-left: 30px;
    margin-right: 30px;
    border-top: 1px dotted $ng4h-icons;
}

.mat-select {
    margin-top: 5xp;
}

mat-drawer {
    padding-top: 10px;
}

// .profile-info {
//     padding: 8px 15px 8px 15px;
//     color: $ng4h-secondary-light;
//     cursor: pointer;
//     position: relative;
//     background-color: $ng4h-primary !important; //border-bottom: 1px solid rgba(0, 0, 0, 0.12);
//     margin-bottom: 20px;
//     height: 65px;
// }
// .profile-info .name {
//     font-weight: 600;
//     font-size: 12pt;
// }
// .profile-info .family-name {
//     margin-top: 11px;
//     font-weight: 600;
//     font-size: 12pt;
// }
.expand-profile-icon {
    right: 0;
    bottom: 25px;
    position: absolute;
}

// .profile-ng4h-nav-link {
//     padding-top: 10px;
// }
.ng4h-nav-link:hover:not(.active):not(.disabled):not(.ng4h-nav-link-no-hover) {
    background-color: rgba(0, 0, 0, .05);
}

.ng4h-nav-link.disabled {
    pointer-events: none;
    cursor: default;
    color: $ng4h-secondary-dark;
}

@import 'variables.scss';
.ng4h-border {
    border: 1px solid $ng4h-border;
}

.ng4h-border-bottom {
    border-bottom: 1px solid $ng4h-border;
}

.ng4h-border-bottom-light {
    border-bottom: 1px solid #efefef; // $ng4h-border;
}

.ng4h-border-bottom-not-last:not(:last-child) {
    border-bottom: 1px solid $ng4h-border;
}

.ng4h-border-right {
    border-right: 1px solid $ng4h-border;
}

.ng4h-border-left {
    border-left: 1px solid $ng4h-border;
}

.ng4h-border-top {
    border-top: 1px solid $ng4h-border;
}

.ng4h-border-vertical {
    border-right: 1px solid $ng4h-border;
    border-left: 1px solid $ng4h-border;
}

.ng4h-border-horizontal {
    border-top: 1px solid $ng4h-border;
    border-bottom: 1px solid $ng4h-border;
}

.ng4h-border-bold {
    border: 2px solid $ng4h-border;
}

.ng4h-border-bottom-bold {
    border-bottom: 2px solid $ng4h-border;
}
.ng4h-border-bottom-not-last:not(:last-child)-bold {
    border-bottom: 2px solid $ng4h-border;
}

.ng4h-border-right-bold {
    border-right: 2px solid $ng4h-border;
}

.ng4h-border-left-bold {
    border-left: 2px solid $ng4h-border;
}

.ng4h-border-top-bold {
    border-top: 2px solid $ng4h-border;
}

.ng4h-border-vertical-bold {
    border-right: 2px solid $ng4h-border;
    border-left: 2px solid $ng4h-border;
}

.ng4h-border-horizontal-bold {
    border-top: 2px solid $ng4h-border;
    border-bottom: 2px solid $ng4h-border;
}

.ng4h-border-radius {
    border-radius: .25rem;
}

.was-validated .ng4h-border.ng-invalid{
    border-color: $form-invalid;
    select {
        border-color: $form-invalid;
    }
}

.creditcard {
	//scale to 50% for crisp appearance on HD displays
	$width:  30px;//actual graphic width is 60px
	$height: 20px;//actual graphic height is 40px
	background-size: 120px 40px;//actual dimensions are 240px 80px;
	background-image: url("/assets/images/creditcards.png");
	background-repeat: no-repeat;
	display: inline-block;
	width: $width;
	height:$height;

	@mixin sprite($rowIndex,$colIndex){
		background-position: ($colIndex * $width * -1) ($rowIndex * $height * -1);
	}
	&.creditcard-Visa            { @include sprite(0,0); }
	&.creditcard-MasterCard      { @include sprite(0,1); }
	&.creditcard-AmericanExpress { @include sprite(0,2); }
	&.creditcard-Discover        { @include sprite(0,3); }
	&.creditcard-DinersClub  	 { @include sprite(1,0); }
	&.creditcard-JCB        	 { @include sprite(1,1); }

	//these properties will make the sprite look nice in the flow of regularly-sized text
	vertical-align: bottom;
	margin-right: 5px;
}

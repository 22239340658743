@import 'variables.scss';
.ng4h-panel {
    margin-bottom: 20px;// $ng4h-margin;
    border: 2px solid $ng4h-border;
    border-radius: 3px;
}

.ng4h-panel-header {
    background-color: $ng4h-secondary;
    border-bottom: 1px solid $ng4h-border;
    padding: 10px;
    .panel-header-buttons {
        border: 1px solid $ng4h-border;
    }
    h3 {
        margin: 0;
    }
}

.ng4h-panel-body {
    padding: 20px;
    background-color: $ng4h-secondary-light;
}

.ng4h-card-header-title {
  margin-bottom: 0;
}

.ng4h-member-card-button {
    margin-bottom: -30px !important;
}

//Example
// <div class="ng4h-panel">
// <div class="ng4h-panel-header">
//   <h3>Details
//     <button class="btn btn-secondary float-right" (click)="edit(training)">Edit</button>
//   </h3>
// </div>
// <div class="ng4h-panel-body ng4h-table">
//   <div class="col-12">
//     <div class="row">
//       <div class="col-4 body-grey"><b>Training Name</b></div>
//       <div class="col-8">{{ training.trainingName }}</div>
//     </div>
//     <div class="row">
//       <div class="col-4 body-grey"><b>Training Mode</b></div>
//       <div class="col-8">{{ training.trainingMode }}</div>
//     </div>
//   </div>
// </div>
// </div>

@import 'variables.scss';

.ng4h-list-row {
    border-bottom: 1px solid $ng4h-border;
    padding: 15px;
    margin: 0px;
    display: block;

    &:hover {
        cursor: pointer;
        background-color: $ng4h-secondary;
    }

    &.disabled {
        pointer-events: none;
        cursor: default;
        background-color: $ng4h-secondary-dark;
    }
}

.mat-mdc-list-item-title {
    font-weight: bold !important;
}
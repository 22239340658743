@import 'variables.scss';

.ng4h-bg-primary {
    background-color: $ng4h-primary;
    color: $ng4h-secondary-light;
}
.ng4h-bg-primary-light {
    background-color: $ng4h-primary-light;
}
.ng4h-bg-secondary {
    background-color: $ng4h-secondary;
}
.ng4h-bg-secondary-light {
    background-color: $ng4h-secondary-light;
}
.ng4h-bg-secondary-dark {
    background-color: $ng4h-secondary-dark;
}
.ng4h-bg-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}
@import 'variables.scss';
@import 'node_modules/bootstrap/scss/bootstrap';

$primary: $ng4h-primary;
$secondary: $ng4h-secondary;
$btn-box-shadow: none;
$input-focus-border-color: $ng4h-primary-light;
$nav-pills-link-active-color: $ng4h-primary-text-color;
$nav-pills-link-active-bg: $ng4h-secondary;

.btn {
  // border: none; //font-size: 18px;
  font-weight: 500;
  /* padding: 8px 40px; */
  min-width: 100px !important;
  white-space: nowrap !important;
}

.btn-primary {
  /*@include button-variant($ng4h-green, darken($ng4h-green, 7.5%), darken($ng4h-green, 10%), lighten($ng4h-green,5%), lighten($ng4h-green, 10%), darken($ng4h-green,30%));*/
  background: linear-gradient(60deg, #42A5F5, #5099DE);
  border: solid 1px #5099DE;

  /*box-shadow: 0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(80, 153, 222,.4);*/
  :hover {
    border: solid 1px #5099DE;
  }
}

.wizard-buttons .btn-primary {
  /*@include button-variant($ng4h-orange, darken($ng4h-orange, 7.5%), darken($ng4h-orange, 10%), lighten($ng4h-orange,5%), lighten($ng4h-orange, 10%), darken($ng4h-orange,30%));
  color: $ng4h-secondary-light;*/
}

.btn.btn-primary:not(.disabled):active {
  background-color: $ng4h-green;
  border-color: $ng4h-green;
}

//button:focus {
//    outline: none !important;
//    box-shadow: none !important;
//}
.was-validated .ng-invalid,
.ng-touched.ng-invalid {
  @extend .is-invalid;
  background-image: none !important;
  // Ok so this causes issues with the inputs changing size when you click on them / they get validated. We keep adding this back
  // or commenting out... not sure why we keep doing it
  // padding-right: 12px !important;
}

.was-validated .ng-valid,
.ng-touched.ng-valid {
  @extend .is-valid;
  background-image: none !important;
  // padding-right: 12px !important;
  border-color: $ng4h-form-control-border  !important;
}

.form-control {
  border-width: 2px;
  border-color: $ng4h-form-control-border;
}

.btn.btn-outline-primary {
  border-color: $ng4h-primary-dark;
  color: $ng4h-primary;
}

.btn.btn-outline-primary i {
  color: $ng4h-primary  !important;
}

.btn.btn-outline-primary:not(.disabled):active {
  background-color: $ng4h-primary;
  border-color: $ng4h-primary-dark;
}

.btn.btn-secondary {
  background-color: $ng4h-secondary;
  border-color: $ng4h-secondary-dark;
  color: $ng4h-bold-text;

  &.right-float-icon {
    float: right;
    clear: both;
    min-width: 36px !important;
  }

  &.icon {
    min-width: 36px !important;
    margin-right: 1rem;
  }

  &:hover {
    background-color: $ng4h-secondary;
  }
}

.btn.btn-secondary-white {
  background-color: white;
  border-color: $ng4h-secondary-dark;
  color: $ng4h-bold-text;

  &.right-float-icon {
    float: right;
    clear: both;
    min-width: 36px !important;
  }

  &.icon {
    min-width: 36px !important;
    margin-right: 1rem;
  }

  &:hover {
    background-color: #efefef;
  }
}

.btn.btn-secondary-red {
  background-color: white;
  border-color: $ng4h-secondary-dark;
  color: #dc3545;

  &.right-float-icon {
    float: right;
    clear: both;
    min-width: 36px !important;
  }

  &.icon {
    min-width: 36px !important;
    margin-right: 1rem;
  }
}

.form-control:focus {
  border-color: $ng4h-primary-light;
}

.form-control:disabled {
  background-color: $ng4h-secondary;
}

legend {
  width: auto !important;
  font-size: inherit !important;
}

.breadcrumb {
  background-color: inherit;
  border-radius: 0;
  border-bottom: 1px solid #efefef;
}

.alert {
  p {
    margin: 0;
  }
}